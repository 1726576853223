<template>
  <v-select
    v-model="servico"
    :items="servicos"
    clearable
    @click:clear="clearServico"
    outlined
    dense
    item-text="servico"
    item-value="servico_id"
    hide-details
    label="Cliente Servico"
  ></v-select>
</template>

<script>
import { fetchClienteServicos } from "@/api/cliente/cliente_servicos.js";
import { fetchEmpresa } from "@/api/cliente/cliente_empresa.js";
import { mapState } from "vuex";

export default {
  name: "ClienteServico",

  data() {
    return {
      servico: null,
      servicos: [],
      loading: false,
      loadingEmpresa: false,
      cliente: {},
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  watch: {
    servico() {
      if (this.servico) {
        this.$store.commit("Filtros/UPDATE_CLIENTE_SERVICO", this.servico);
      }
    },
  },

  methods: {
    clearServico() {
      this.$store.commit("Filtros/UPDATE_CLIENTE_SERVICO", null);
      this.servico = null;
    },

    getClienteEmpresa() {
      this.loadingEmpresa = true;
      return fetchEmpresa(this.user_id)
        .then((response) => {
          this.cliente = response;
          this.loadingEmpresa = false;
        })
        .catch(() => {
          this.loadingEmpresa = false;
        });
    },

    getClienteServicos() {
      this.loading = true;
      fetchClienteServicos(this.cliente.id)
        .then((response) => {
          this.servicos = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getClienteEmpresa();
    await this.getClienteServicos();
    if (this.$route.query.servico) {
      let servico = this.servicos.find(
        (item) => item.value == this.$route.query.servico
      );
      this.servico = servico.value;
    }
  },
};
</script>

<style></style>
